// main
$white: white;
$gray: gray;

$blue-main: #101336;

$gray-dark: #151515;
$gray-main: #333333;
$gray-hint: #646f84;
$gray-border-dark: #7F7F7F;
$gray-border: #CCCCCC;
$gray-light: #D8DFE0;
$gray-ultralight: #F2F2F2;
$red-dark: #de1609;
$red: #eb1730;

$background-main: #FFFFFF;
$background-alt: #F7F7F7;
$background-dimmer: rgba(0, 0, 0, 0.75);
$background-footer: #6d3adf;
