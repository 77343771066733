@import "../variables/layout.scss";

%wrapper {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  position: relative;
  margin: 0 auto;
  height: auto;
  max-width: $wrapper-width;
  width: 100%;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile-only {
    padding: 1rem 0 2rem;
  }
}

%wrapper-element {
  max-width: 530px;
  width: 100%;
}

.wrapper {
  @extend %wrapper;
}

.hr-wrapper {
  max-width: $wrapper-width;
  margin: 0 auto;
  padding: 0 1rem;
}
