@import "../mixins/breakpoints.scss";
@import "../variables/layout.scss";

$grid_cols: 16;
$gap: 0.5rem;

.grid {
  max-width: $wrapper-width;

  &-fluid {
    margin: 0;
    max-width: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    &.align-left { justify-content: left; }
    &.align-center { justify-content: center; }
    &.align-right { justify-content: right; }
  
    .col:first-child {
      margin-left: 0;
    }
    .col:last-child {
      margin-right: 0;
    }

    @for $i from 1 through $grid_cols {
      .col-#{$i} {
        $w: calc(100 / ($grid_cols / $i)) * 1%;
        flex-basis: $w;
        max-width: calc( $w - $gap );
        // max-width: calc( $w - 0rem );
        // margin-left: calc( $gap / 2 );
        // margin-right: calc( $gap / 2);
      }
    }
    .col-16 { max-width: 100%; width: 100%; }

    @include mobile-only {
      @for $i from 1 through $grid_cols {
        .col-md-#{$i} {
          $w: calc(100 / ($grid_cols / $i)) * 1%;
          flex-basis: $w;
          max-width: calc( $w - $gap );
          // max-width: calc( $w - 0rem );
          // margin-left: calc( $gap / 2 );
          // margin-right: calc( $gap / 2);
        }
      }
      .col-md-16 { max-width: 100%; width: 100%; }
    }
  }
}