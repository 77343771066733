@use '@angular/material' as mat;

@include mat.core();

body {
    font-family: "Lato-Regular", "Lato Regular", "Lato", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: $gray-main;
    margin: 0;
}

h1, h2, h3 {
    font-family: "Lato-Bold", "Lato Bold", "Lato", sans-serif;
    font-weight: 700;
    color: $gray-dark;
}
h1 { font-size: 2rem; }
h2 { font-size: 1.25rem; }
h3 { font-size: 1rem; }
h4 { font-size: 0.875; }

a,
.text-link,
.tertiary {
    color: var(--main-element-color);
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover { opacity: 0.7; }
}
a.link  { font-weight: 800; }
a,
.text-link:hover { text-decoration: underline; }

button,
.button {
    color: var(--alternative-element-color);
    cursor: pointer;
    font-family: "Lato-Black", "Lato Black", "Lato", sans-serif;
    font-size: 1rem;
    background-color: var(--main-element-color);
    border-radius: 3;
    border: none;
    padding: 0.75rem 3rem;
    font-weight: 700;
    transition: all 0.2s ease;
    &:hover {
        opacity: 0.8;
    }
    &:active {
        background-color: var(--main-element-color);
        color: $gray-dark;
    }
    &.disabled {
        background-color: $gray-light;
        cursor: not-allowed;
    }
    &.secondary {
        border: 1px solid transparent;
        background-color: transparent;
        box-shadow:
            rgba(0, 0, 0, 0.02) 0px 2px 16px,
            rgba(0, 0, 0, 0.04) 0px 2px 7px,
            rgba(0, 0, 0, 0.04) 0px 1px 3.5px,
            rgba(0, 0, 0, 0.04) 0px 1px 2.0px,
            rgba(0, 0, 0, 0.05) 0px 0px 1px,
            rgba(0, 0, 0, 0.06) 0px 0px 0.5px;
        &:hover, &:active {
            color: $white;
            background-color: $gray-dark;
        }
    }
}


.bp-widget-container .mat-dialog-container {
    padding: 0 !important;
}

.radio {
    margin: 0.5rem;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        font-size: 12px;
        &:before {
          content: '';
          border-radius: 100%;
          border: 1px solid var(--main-element-color);
          display: inline-block;
          width: 1em;
          height: 1em;
          position: relative;
          margin-right: 0.5rem; 
          vertical-align: top;
          cursor: pointer;
          text-align: center;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: var(--main-element-color);
            border: 1px solid var(--main-element-color);
            box-shadow: inset 0 0 0 4px $white;
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
